import tw from "twin.macro";
import styled from "styled-components";

const StyledButton = styled.button`
  ${tw`p-6 rounded-md border-none m-6 text-white cursor-pointer`}

  background-color: #1976d2;

  &:hover {
    background-color: #1357a6;
  }
`;
type ButtonProps = {
  text: string;
  onClick: any;
};

export function PrimaryButton({ text, onClick }: ButtonProps) {
  return <StyledButton onClick={onClick}>{text}</StyledButton>;
}
