import tw from "twin.macro";
import styled from "styled-components";
import {
  CharacterClassByRollsParameters,
  CharacterOptionsService,
} from "../../api/CharacterOptionsService";
import { CharacterOptionDTO } from "../../api/dto/CharacterOptionDTO";
import { useEffect, useState } from "react";
import { CharacterOptionsDataGrid } from "../../components/DataGrids/CharacterOptionsDataGrid";
import { NumberInput } from "../../components/Inputs/NumberInput";
import { PrimaryButton } from "../../components/Buttons/PrimaryButton";

const RollsContainer = styled.div`
  ${tw`flex justify-center`}

  background-color: aliceblue;
  border-radius: 15px;
`;

const Container = styled.div`
  ${tw` m-2 mt-0 rounded-md`}

  height: fit-content
`;

type CharacterOption = CharacterOptionDTO & {
  primeAttributes: string;
};

const MIN_ABILITY_SCORE = 3;
const MAX_ABILITY_SCORE = 18;

export default function CharacterCreationOptionsPage() {
  const [characterOptions, setCharacterOptions] = useState<
    Array<CharacterOptionDTO>
  >([]);
  const [strength, setStrength] = useState<number>(10);
  const [intelligence, setIntelligence] = useState<number>(10);
  const [wisdom, setWisdom] = useState<number>(10);
  const [dexterity, setDexterity] = useState<number>(10);
  const [constitution, setConstitution] = useState<number>(10);
  const [charisma, setCharisma] = useState<number>(10);
  const [statsUpdated, setStatsUpdated] = useState<boolean>(false);
  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);

  const randomizeStats = (): void => {
    setStrength(getRandomStat());
    setIntelligence(getRandomStat());
    setWisdom(getRandomStat());
    setDexterity(getRandomStat());
    setConstitution(getRandomStat());
    setCharisma(getRandomStat());
    setStatsUpdated(true);
  };

  const getCharacterOptions = async () => {
    console.log(
      "setting character options current stats:  ",
      strength,
      " ",
      intelligence,
      " ",
      wisdom,
      " ",
      dexterity,
      " ",
      constitution,
      " ",
      charisma
    ); //debug statement
    const data = await getData(getCharacterClassByRollsParameters());
    const dataWithPrimeAttributeAssigned = assignPrimeAttributes(data);

    setCharacterOptions(dataWithPrimeAttributeAssigned);
  };

  const getCharacterClassByRollsParameters =
    (): CharacterClassByRollsParameters => {
      return {
        str: strength,
        int: intelligence,
        wis: wisdom,
        dex: dexterity,
        con: constitution,
        cha: charisma,
      };
    };

  useEffect(() => {
    if (statsUpdated || isInitialLoad) getCharacterOptions();
    setStatsUpdated(false);
    setIsInitialLoad(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statsUpdated]);

  return (
    <Container>
      <RollsContainer>
        <NumberInput
          value={strength}
          min={MIN_ABILITY_SCORE}
          max={MAX_ABILITY_SCORE}
          onChange={(v: any) => {
            setStrength(v.target.value);
          }}
          label={"Strength"}
        />
        <NumberInput
          value={intelligence}
          min={MIN_ABILITY_SCORE}
          max={MAX_ABILITY_SCORE}
          onChange={(v: any) => {
            setIntelligence(v.target.value);
          }}
          label={"Intelligence"}
        />
        <NumberInput
          value={wisdom}
          min={MIN_ABILITY_SCORE}
          max={MAX_ABILITY_SCORE}
          onChange={(v: any) => {
            setWisdom(v.target.value);
          }}
          label={"Wisdom"}
        />
        <NumberInput
          value={dexterity}
          min={MIN_ABILITY_SCORE}
          max={MAX_ABILITY_SCORE}
          onChange={(v: any) => {
            setDexterity(v.target.value);
          }}
          label={"Dexterity"}
        />
        <NumberInput
          value={constitution}
          onChange={(v: any) => {
            setConstitution(v.target.value);
          }}
          label={"Constitution"}
        />
        <NumberInput
          value={charisma}
          min={MIN_ABILITY_SCORE}
          max={MAX_ABILITY_SCORE}
          onChange={(v: any) => {
            setCharisma(v.target.value);
          }}
          label={"Charisma"}
        />
        <PrimaryButton
          text={"Get Options"}
          onClick={async () => {
            try {
              await getCharacterOptions();
            } catch (e) {
              setCharacterOptions([]);
            }
          }}
        ></PrimaryButton>
        <PrimaryButton
          text="Randomize Stats"
          onClick={() => {
            randomizeStats();
            //getCharacterOptions();
          }}
        ></PrimaryButton>
      </RollsContainer>

      <CharacterOptionsDataGrid
        data={characterOptions}
        height={"770px"}
      ></CharacterOptionsDataGrid>
    </Container>
  );
}

const getData = async (params: CharacterClassByRollsParameters) => {
  return await CharacterOptionsService.getCharacterClassesByRolls(params).then(
    (d) => {
      console.log(d.data);
      return d.data;
      //setCharacterOptions(d.data);
    }
  );
};

const assignPrimeAttributes = (characterOptions: CharacterOption[]) => {
  const updatedCharacterOptions: CharacterOption[] = characterOptions.map(
    (option: CharacterOption): CharacterOption => {
      const primeAttributes = [];
      if (option.isStrengthPrimeAttribute) {
        primeAttributes.push("Strength");
      }
      if (option.isWisdomPrimeAttribute) {
        primeAttributes.push("Wisdom");
      }
      if (option.isConstitutionPrimeAttribute) {
        primeAttributes.push("Constitution");
      }
      if (option.isDexterityPrimeAttribute) {
        primeAttributes.push("Dexterity");
      }
      if (option.isIntelligencePrimeAttribute) {
        primeAttributes.push("Intelligence");
      }
      if (option.isCharismaPrimeAttribute) {
        primeAttributes.push("Charisma");
      }
      option.primeAttributes = primeAttributes.join(", ");
      return option;
    }
  );
  return updatedCharacterOptions;
};

const d6 = (): number => {
  return Math.floor(Math.random() * 6) + 1;
};
const getRandomStat = (): number => d6() + d6() + d6();
