import tw from "twin.macro";
import styled, { css } from "styled-components";
import { InputLabel } from "@mui/material";

type InputProps = NumberInputProps & {
  label: string;
  onChange?: any;
  value: any;
};

type NumberInputProps = {
  min?: number;
  max?: number;
};

const StyledInputLabel = styled(InputLabel)`
  ${tw`mt-5`}
`;

const StyledNumberInput = styled.input<NumberInputProps>`
  ${tw`m-7 w-40 text-center rounded-md`}

  border: none;
  border-bottom: #a8d7ed;
  border-bottom-style: solid;
  
  &:focus {
    outline: 0px;
    border-bottom: #2E7495;
    border-bottom-style: solid;
  }
  
  ${(props) =>
    props.min &&
    css`
      min: ${props.min};
    `};
  ${(props) =>
    props.max &&
    css`
      max: ${props.max};
    `};};
`;

const Container = styled.div`
  ${tw``}
`;

export function NumberInput({ label, onChange, value, min, max }: InputProps) {
  return (
    <Container>
      <StyledInputLabel>{label}</StyledInputLabel>
      <StyledNumberInput
        min={min}
        max={max}
        value={value}
        onChange={onChange}
        type="number"
      ></StyledNumberInput>
    </Container>
  );
}
