import { DataGrid, GridColDef } from "@mui/x-data-grid";
import Box from "@mui/material/Box";

type AppDataGridProps = {
  data: any;
  height?: string;
  //columns: any;
};

export function CharacterOptionsDataGrid({ data, height }: AppDataGridProps) {
  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "raceName",
      headerName: "Race Name",
      width: 150,
      editable: false,
    },
    {
      field: "className",
      headerName: "Class Name",
      width: 150,
      editable: false,
    },
    {
      field: "strengthBonus",
      headerName: "Str Bonus",
      width: 150,
      editable: false,
    },
    {
      field: "intelligenceBonus",
      headerName: "Int Bonus",
      width: 150,
      editable: false,
    },
    {
      field: "wisdomBonus",
      headerName: "Wis Bonus",
      width: 150,
      editable: false,
    },
    {
      field: "dexterityBonus",
      headerName: "Dex Bonus",
      width: 150,
      editable: false,
    },
    {
      field: "constitutionBonus",
      headerName: "Con Bonus",
      width: 150,
      editable: false,
    },
    {
      field: "charismaBonus",
      headerName: "Cha Bonus",
      width: 150,
      editable: false,
    },
    {
      field: "primeAttributes",
      headerName: "Prime Attributes",
      width: 150,
      editable: false,
    },
  ];

  return (
    <Box sx={{ height: height ? height : "770px", width: "100%" }}>
      <DataGrid
        rows={data}
        columns={columns}
        pageSize={70}
        rowsPerPageOptions={[70]}
        checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
        getRowId={(row) => row.id}
      />
    </Box>
  );
}
