import styled from "styled-components";
import tw from "twin.macro";
import { useNavigate } from "react-router-dom";
import logo from "../images/dnd-icon.png";

const NavbarContainer = styled.nav`
  ${tw`flex w-full h-14`}

  background-color: orange;
`;

const LogoHome = styled.div`
  ${tw`text-white h-full cursor-pointer`}

  width: 10%;

  background-color: blue;

  &:hover {
    background-color: darkblue;
  }
`;

const SubNavBar = styled.div`
  ${tw`flex`}
  width: 65%
`;
const SubUserNavBar = styled.div`
  ${tw`inline-block`}
  width: 25%
`;

const NavBarItem = styled.div`
  ${tw`text-white w-full h-full cursor-pointer`}

  background-color: green;

  &:hover {
    background-color: darkgreen;
  }
`;

const UserNavBarItem = styled.div`
  ${tw`text-white h-full cursor-pointer`}

  float: right;
  width: 40%;
  background-color: red;

  &:hover {
    background-color: darkred;
  }
`;

const Navbar = () => {
  const navigate = useNavigate();

  return (
    <NavbarContainer>
      <LogoHome onClick={() => navigate("/")}>
        <img width={"100px"} height={"50px"} src={logo} alt="logo" />
      </LogoHome>
      <SubNavBar>
        <NavBarItem onClick={() => navigate("/character-creation")}>
          <p>Character Creation</p>
        </NavBarItem>
        <NavBarItem onClick={() => navigate("/campaign-management")}>
          <p>Campaign Management</p>
        </NavBarItem>
        <NavBarItem onClick={() => navigate("/generators")}>
          <p>Generators</p>
        </NavBarItem>
        <NavBarItem onClick={() => navigate("/rules-ref")}>
          <p>Rules Reference</p>
        </NavBarItem>
        <NavBarItem onClick={() => navigate("/admin")}>
          <p>Admin</p>
        </NavBarItem>
      </SubNavBar>
      <SubUserNavBar>
        <UserNavBarItem onClick={() => navigate("/profile")}>
          <p>Profile</p>
        </UserNavBarItem>
      </SubUserNavBar>
    </NavbarContainer>
  );
};

export default Navbar;
