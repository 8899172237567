import React from "react";
//import logo from "./logo.svg";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import CharacterCreation from "./pages/CharacterCreation/CharacterCreationOptionsPage";
import Admin from "./pages/Admin/Admin";
import CampaignManagement from "./pages/CampaignManagement/CampaignManagement";
import Generators from "./pages/Generators/Generators";
import Profile from "./pages/Profile/Profile";
import RulesReference from "./pages/RulesReference/RulesReference";
import Home from "./pages/Home/Home";
import Navbar from "./components/Navbar";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/character-creation" element={<CharacterCreation />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/campaign-management" element={<CampaignManagement />} />
        <Route path="/generators" element={<Generators />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/rules-ref" element={<RulesReference />} />
      </Routes>
    </div>
  );
}

export default App;
