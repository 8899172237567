import axios from "axios";

export type CharacterClassByRollsParameters = {
  str: number;
  int: number;
  wis: number;
  dex: number;
  con: number;
  cha: number;
};
const HOST = "http://oserpg.com/api"//"http://localhost:8080";
const CONTROLLER = "/character-options";
export const CharacterOptionsService = {
  getCharacterClassesByRolls: ({
    str,
    int,
    wis,
    dex,
    con,
    cha,
  }: CharacterClassByRollsParameters) => {
    const res = axios({
      headers: {
        "Access-Control-Request-Private-Network": true,
        "Content-Type": "application/json;charset=UTF-8",
        "Referrer-Policy": "strict-origin-when-cross-origin",
      },
      method: "get",
      url: `${HOST}${CONTROLLER}/class-options-by-rolls?str=${str}&int=${int}&wis=${wis}&dex=${dex}&con=${con}&cha=${cha}`,
    });
    return res;
  },
};
